<template>
	<div>
		<v-row>
			<v-col cols="12" class="d-flex justify-end">
				<v-btn color="primary" @click="navigateToCreateTask">Create Task</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12">
				<v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
        <v-data-table
          :headers="headers"
          :items="issues"
          :search="search"
          class="elevation-1 mt-4"
        >
          <template v-slot:item.action="{ item }">
            <v-btn color="primary" @click="navigateToIssue(item._id)">View</v-btn>
          </template>
        </v-data-table>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import RestResource from "../../../services/dataServiceMobileV2";
const service = new RestResource();

export default {
	data() {
		return {
			issues: [],
			headers: [
				{ text: "UserId", value: "userId" },
				{ text: "Email", value: "email" },
				{ text: "Submitted At", value: "createdAt" },
				{ text: "Actions", value: "action", sortable: false },
			],
			search: ''
		};
	},

	mounted() {
		this.getAllIssues();
	},

	methods: {
		getAllIssues() {
			this.$setLoader()
			service.getAllIssues().then((r) => {
				this.issues = r.data.data;
			});
			this.$disableLoader()
		},
		navigateToIssue(issueId) {
			this.$router.push({ name: 'MobileV2Issue', params: { issueId } });
		}
	},
};
</script>

<style scoped>
.d-flex {
	display: flex;
}

.justify-end {
	justify-content: flex-end;
}
</style>